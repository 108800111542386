import React, { useState } from 'react';

import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { Layout, Post, Video, Book, Podcast, JoinCommunity } from '../components/common'

import { MetaData } from '../components/common/meta'

import styles from './library_item.module.css'

const LibraryItem = ({ pageContext, location }) => {
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    const headerTitle = pageContext.headerTitle
    const type = pageContext.type
    const item = pageContext.item

    const name = item["name"]
    let description = item["description"]
    if (Array.isArray(description)) {
        description = description[0]
    }

    const videos = item["videos"]
    const books = item["books"]
    const podcasts = item["podcasts"]
    const posts = item["featured_posts"]
    const citations = item["citations"]
    const numPosts = item["post_count"]
    const numUsers = item["user_count"]

    // Diet specific
    const diet_foodsToEat = item["foods_to_eat"]
    const diet_foodsToSkip = item["foods_to_skip"]
    const diet_whatStudiesSay = item["what_studies_say"]
    const diet_proTip = item["pro_tip"]
    const diet_effortLevel = item["effort_level"]

    let activityMessagePeople = `People on Wana with ${name}`
    if (type === "treatment" || type === "diet") {
      activityMessagePeople = `People on Wana trying ${name}`
    }

    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        var title = text.replace(urlRegex, '')
        var modified = text.replace(urlRegex, function(url) {
          return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + title + '</a>';
        })
        return modified
      }

    return (
        <>
            <MetaData location={location} title={pageContext.title} description={pageContext.description}/>
            <Layout pageContext={pageContext}>
                <div className="container" style={{padding: "20px"}}>
                    <div style={{display: "flex", margin: "10px 0px"}}>
                        <Link to="/library/" className="link">
                            &#8592; Back to the Library
                        </Link>
                    </div>
                    <div style={{display: "flex"}}>
                        <img src={`/images/icons/${type}.svg`} alt={type} style={{height: "50px", width: "50px"}}/>
                        <h1 style={{marginLeft: "20px"}}>{name}</h1>
                    </div>
                    <h2>{headerTitle}</h2>
                    <div className="row">
                        <div className="col-12 col-sm-8 col-md-8">
                          <p className={descriptionOpen ? styles.descriptionOpen : styles.description}>{description}</p>
                          {description.length > 200 && <button className="btn btn-outline-primary center" onClick={() => setDescriptionOpen(!descriptionOpen)}>{descriptionOpen ? "Collapse" : "Read More"}</button>}
                          <div className={styles.spacer}/>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4">
                          <div className="row">
                            <div className="col-12">
                              <h3 className={styles.activityHeader}>Wana Activity</h3>
                            </div>
                            <div className="col-12">
                              <div className={styles.activitySection}>
                                  <img src={`/images/icons/library_item_people.svg`} alt={"people"} style={{height: "50px", width: "50px"}}/>
                                  {numUsers > 0 && <div className={styles.activityNumber}>{numUsers}</div>}
                                  {numUsers === 0 && <div className={styles.activityEmpty}>{"Join Wana and start the community!"}</div>}
                              </div>
                              {numUsers > 0 && <div className={styles.activityMsg}>{activityMessagePeople}</div>}
                            </div>
                            <div className="col-12">
                              <div className={styles.activitySection}>
                                  <img src={`/images/icons/library_item_chat.svg`} alt={"chat"} style={{height: "50px", width: "50px"}}/>
                                  {numPosts > 0 && <div className={styles.activityNumber}>{numPosts}</div>}
                                  {numPosts === 0 && <div className={styles.activityEmpty}>{"Start the conversation on Wana!"}</div>}
                              </div>
                              {numPosts > 0 && <div className={styles.activityMsg}>{`Wana posts about ${name}`}</div>}
                            </div>
                          </div>
                        </div>
                    </div>
                    {type === "diet" &&
                      <div>
                        <div style={{height: "30px"}}/>
                        {diet_foodsToEat && <h3>Foods to Eat</h3>}
                        {diet_foodsToEat && <p>{diet_foodsToEat}</p>}

                        {diet_foodsToSkip && <h3>Foods to Avoid</h3>}
                        {diet_foodsToSkip && <p>{diet_foodsToSkip}</p>}

                        {diet_whatStudiesSay && <h3>What Studies Say</h3>}
                        {diet_whatStudiesSay && <p>{diet_whatStudiesSay}</p>}

                        {diet_effortLevel && <h3>Effort Level</h3>}
                        {diet_effortLevel && <p>{diet_foodsToEat}</p>}
                      </div>
                    }
                </div>
                {posts && posts.length > 0 &&
                    <>
                        <div className="container-fluid" style={{padding: "20px", background: "#f8f8f8"}}>
                            <h2 style={{textAlign: "center"}}>{`Recent posts about ${name}`}</h2>
                            <div className="row">
                                <div className="col-md-12 col-lg-10 offset-lg-1">
                                    <div className="row">
                                        {posts.map((post, i) => (
                                            <div key={i} className="col-12 col-md-4">
                                                <Post post={post}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="container" style={{padding: "20px"}}>
                    {videos && videos.length > 0 &&
                        <>
                            <h2>{`Videos about ${name}`}</h2>
                            <div style={{background: "#979797", width: "100%", height: "1px", margin: "10px 0px", opacity: 0.2}}/>
                            {<div className="row">
                                {videos.map((video, i) => (
                                    <div key={i} className="col-12 col-md-6">
                                        <Video video={video}/>
                                    </div>
                                ))}
                            </div>}
                        </>
                    }
                    {books && books.length > 0 &&
                        <>
                            <h2 style={{marginTop: "20px"}}>{`Books about ${name}`}</h2>
                            <div style={{background: "#979797", width: "100%", height: "1px", margin: "10px 0px", opacity: 0.2}}/>
                            <div className="row">
                                {books.map((book, i) => (
                                    <div key={i} className="col-12 col-md-4">
                                        <Book book={book}/>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                    {podcasts && podcasts.length > 0 &&
                        <>
                            <h2 style={{marginTop: "20px"}}>{`Podcasts about ${name}`}</h2>
                            <div style={{background: "#979797", width: "100%", height: "1px", margin: "10px 0px", opacity: 0.2}}/>
                            <div className="row">
                                {podcasts.map((podcast, i) => (
                                    <div key={i} className="col-12 col-md-4">
                                        <Podcast podcast={podcast}/>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
                <JoinCommunity version={2}/>
                {citations && citations.length > 0 &&
                    <div className="container">
                        <h2>Resources</h2>
                        <ul>
                            {citations.map((citation, i) => (
                                <li key={i} style={{fontSize: "10pt"}}><div dangerouslySetInnerHTML={{ __html: urlify(citation) }} /></li>
                            ))}
                        </ul>
                    </div>
                }
                <div style={{height: "40px"}}></div>
            </Layout>
        </>
    )
}

LibraryItem.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default LibraryItem
